import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";

const capitalise = (input: string) =>
  input
    .split(" ")
    .map(
      (splitInput) => splitInput.charAt(0).toUpperCase() + splitInput.slice(1)
    )
    .join(" ");

const NameInput = (): React.ReactElement => {
  const history = useHistory();
  const [rowerName, setRowerName] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) =>
    setRowerName(event.target.value);

  const handleButtonClick = () => history.push(`/${capitalise(rowerName)}`);

  return (
    <form
      onSubmit={handleButtonClick}
      className="flex flex-col items-center pt-10 space-y-4 px-4 max-w-md mx-auto"
    >
      <label htmlFor="rower-name-input" className="text-xl m-x-auto self-start">
        Search for a rower:
      </label>
      <input
        id="rower-name-input"
        type="text"
        value={rowerName}
        onChange={handleInputChange}
        className="border rounded-xl p-4 self-stretch"
      />
      <button
        type="submit"
        disabled={!rowerName}
        className={classnames(
          "self-stretch bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        )}
      >
        Search
      </button>
    </form>
  );
};

export default NameInput;
