import React from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch } from "use-http";

type EntryType = {
  boat: string;
  crew: string[];
};

type RaceType = {
  code: string;
  title: string;
  distance: string;
  entries: EntryType[];
};

type RegattaType = {
  regattaId: string;
  races: RaceType[];
  title: string;
};

const filterRegattas = (
  regattas: RegattaType[],
  rowerName: string
): RegattaType[] => {
  if (!regattas) {
    console.log(rowerName);
    console.log();
  }
  return regattas.filter((regatta) =>
    regatta.races.some((race) =>
      race.entries.some((entry) => entry.crew.includes(rowerName))
    )
  );
};

const Race = ({
  regatta,
  year,
  rowerName,
  race,
}: {
  regatta: RegattaType;
  year: string;
  rowerName: string;
  race: RaceType;
}): React.ReactElement => (
  <section>
    <h4 className="text-sm font-semibold leading-tight">
      {race.code} - {race.distance}
    </h4>
    <p className="text-sm leading-tight">
      {race.entries
        .find((entry) => entry.crew.find((crew) => crew.includes(rowerName)))
        ?.crew.map((crewName, index) => (
          <span
            key={`${regatta.title}-${year}-${race.code}-${crewName}-${index}`}
          >
            {index ? ", " : ""}
            <Link
              to={`/${crewName}`}
              className="text-gray-400 hover:text-gray-800"
            >
              {crewName}
            </Link>
          </span>
        ))}
    </p>
  </section>
);

const Regatta = ({
  rowerName,
  year,
  regatta,
}: {
  rowerName: string;
  year: string;
  regatta: RegattaType;
}): React.ReactElement => (
  <section className="h-full border border-gray-200 p-4 rounded-sm flex flex-col justify-center shadow-md">
    <h2 className="text-l leading-none font-semibold pb-2">
      {regatta.title.replace(year, "").trim()}
    </h2>
    <ul className="space-y-2">
      {regatta.races
        .filter((race) =>
          race.entries.some((entry) => entry.crew.includes(rowerName))
        )
        .map((race) => (
          <li key={race.code}>
            <Race
              regatta={regatta}
              year={year}
              rowerName={rowerName}
              race={race}
            />
          </li>
        ))}
    </ul>
  </section>
);

const Year = ({
  year,
  rowerName,
}: {
  year: string;
  rowerName: string;
}): React.ReactElement | null => {
  const { data: regattas = [] } = useFetch<RegattaType[]>(
    `./${year}.json`,
    {},
    [year]
  );

  if (!filterRegattas(regattas, rowerName).length) {
    return null;
  }

  return (
    <section className="w-full text-center flex flex-col">
      <h2 className="text-3xl font-semibold pb-4">{year} Season</h2>
      <ul className="grid grid-cols-1 md:grid-cols-3 md:gap-4 gap-2">
        {filterRegattas(regattas, rowerName).map((regatta) => (
          <li key={regatta.regattaId} className="self-stretch">
            <Regatta rowerName={rowerName} year={year} regatta={regatta} />
          </li>
        ))}
      </ul>
    </section>
  );
};

const App = (): React.ReactElement => {
  const { rowerName } = useParams<{
    rowerName: string;
  }>();
  const { loading, error, data: years = [] } = useFetch<string[]>(
    "./years.json",
    {},
    []
  );

  if (loading) {
    return <div className="animate-pulse text-5xl text-center pt-8">...</div>;
  }
  if (error) return <div>Error</div>;

  return (
    <section className="flex justify-center">
      <section className="h-full md:w-3/4 flex flex-col px-2 md:px-0">
        <header className="w-full flex items-center justify-between py-8">
          <Link
            to="/"
            className="text-4xl font-extrabold text-gray-400 hover:text-gray-800"
          >
            &lt;
          </Link>
          <h1 className="text-5xl font-semibold text-center">{rowerName}</h1>
          <span />
        </header>
        <main>
          <ul className="space-y-4">
            {years.map((year) => (
              <li key={year}>
                <Year year={year} rowerName={rowerName} />
              </li>
            ))}
          </ul>
        </main>
      </section>
    </section>
  );
};

export default App;
